import { createStore } from "vuex";
import axios from 'axios'
// import VueAxios from 'vue-axios'

const store = createStore({
    state: {
        title: "Chessfruits",
        lang: "de",
        level: 1,
        game_art : 'training',
        timer_is_on: false,
        notation_arr: [],
        nota_de: ["","L","S","T","D","K","","L","S","T","D","K"],
        nota_en: ["","B","N","R","Q","K","","B","N","R","Q","K"],
        imgArr : ['sB','sL','sS','sT','sD','sK','wB','wL','wS','wT','wD','wK'],
        move: "",
        move_arr: [],
        colums: ['a','b','c','d','e','f','g','h'],
        lineup: ["sT","sS","sL","sD","sK","sL","sS","sT",
            "sB","sB","sB","sB","sB","sB","sB","sB",
            "","","","","","","","","","","","","","","","",
            "","","","","","","","","","","","","","","","",
            "wB","wB","wB","wB","wB","wB","wB","wB",
            "wT","wS","wL","wD","wK","wL","wS","wT" ],
        lineupBackup: [],
        lineupGame: ["sT","sS","sL","sD","","","","",
            "","","","","","","","",
            "","","","","","","","","","","","","","","","",
            "","","","","","","","","","","","","","","","",
            "","","","","","","","",
            "","","","","wD","wL","wS","wT" ],
        lineupGameHeavy: ["sT","sS","sL","sD","sL","sS","sT","",
            "","","","","","","","",
            "","","","","","","","","","","","","","","","",
            "","","","","","","","","","","","","","","","",
            "","","","","","","","",
            "","wT","wS","wL","wD","wL","wS","wT"],
        payload: {
            piece: "",
            pieceImg: "",
            feldname_from : "",
            notation_from : "",
            rotador: "nosw"
        },
        fieldNames : [
            "a8","b8","c8","d8","e8","f8","g8","h8",
            "a7","b7","c7","d7","e7","f7","g7","h7",
            "a6","b6","c6","d6","e6","f6","g6","h6",
            "a5","b5","c5","d5","e5","f5","g5","h5",
            "a4","b4","c4","d4","e4","f4","g4","h4",
            "a3","b3","c3","d3","e3","f3","g3","h3",
            "a2","b2","c2","d2","e2","f2","g2","h2",
            "a1","b1","c1","d1","e1","f1","g1","h1",
        ],
        dbId : ""
    },
    getters: {
        totalNotes(state){
            return state.move_arr.length;
        },
    },
    mutations:{
        save_move(state){
            let move = ""; // new move made
            let piece = state.payload.piece;
            let quellnota = state.payload.notation_from;
            let zielnota = state.payload.notation_target

            let oldField = state.payload.feldname_from;
            let res = oldField.split("feld");
            let figur = state.lineup[res[1]-1]; // getting the peace that moves
                state.lineup[res[1]-1] = ""; // empty the old place

            let newField = state.payload.feldname_target;
            let res2 = newField.split("feld");// getting new pos of peace
            let schlagzug = state.lineup[res2[1]-1]; // wird figur gekillt?
                state.lineup[res2[1]-1] = figur// set figur to new place

            // console.log('Piece: ' + state.payload.piece + ' feldname_target: ' + newField )

            if(piece != ""){
                let movefrom = piece + quellnota
                if(schlagzug == "" ){ move = movefrom + '-'+zielnota;}
                else{                 move = movefrom +'x'+zielnota; }
            }else{ // Bauernzug
                if(schlagzug == "" ){ move = quellnota + '-'+zielnota;}
                else{ // Schlagzug
                    let res = quellnota.slice(0, 1);
                    move = res+'x'+zielnota;
                }
            }
            state.move = move;
        },
        clear_move(state){
            state.move = "";
        },
        clear_move_arr(state){
            state.move_arr = [];
        },
        setNota(state) {
            let val = "";

            for(let i = 0; i < state.imgArr.length; i++) {
                let key = state.imgArr[i];
                if(state.lang == "en"){ val = state.nota_en[i]; }
                else{                   val = state.nota_de[i]; }
                state.notation_arr[key] = val;
            }
            return state.notation_arr;
        },
        makeBackup(state, init){
            if(init == "init"){  state.lineupBackup = JSON.parse(JSON.stringify(state.lineup))
            }else{               state.lineup = JSON.parse(JSON.stringify(state.lineupBackup))
                /* could be done here
                for (let i = 0; i < store.state.lineup.length; i++) {
                    let feldId = "feld" + (i+1)
                    document.getElementById(feldId).innerHTML = "<div class='"+store.state.lineup[i]+"'></div>";
                }*/
                // console.log('lineup' +  state.lineup.join(","))
            }
        },
        initGame(state, number){
            if(number==14){
                this.commit("shuffle", state.lineupGameHeavy);
                state.lineup = JSON.parse(JSON.stringify(state.lineupGameHeavy))
            }else{
                this.commit("shuffle", state.lineupGame);
                state.lineup = JSON.parse(JSON.stringify(state.lineupGame))
            }
        },
        shuffle(state, payload){
            let numbers = payload;
            let first,
                second,
                temp,
                count = numbers.length;
            for (let i = 0; i < count; i++) {
                first = Math.floor(Math.random() * count);
                second = Math.floor(Math.random() * count);
                temp = numbers[first];
                numbers[first] = numbers[second];
                numbers[second] = temp;
            }
            payload = numbers;
        },
        handleDbId(state, resultBdRow){/* Created ID returned from schachraetsel DB */
            state.dbId = resultBdRow;
            // .. input posibility for a highscore alias ?
            // console.log('resultBdRow: '+resultBdRow)
        }
    },
    actions:{
           writetoDb({commit}, payload){
               let points = payload.points
               let level = payload.level
               // let host = "";
               let pId = 0;
              let req = { p: points, level: level };
               if(points != 0){ //axios.get("https://chessfruits.schachraetsel.com/_API/?p="+points
                   axios.post("https://chessfruits.schachraetsel.com/_API/", req)
                       .then(response => {
                            // ip = response.data.ip; // Vodaphone Home Ip (It's a Browser App!)
                       //    host = response.data.host
                           pId = response.data.pId
                          // console.log('host: '+host+' PID: '+pId)

                           commit('handleDbId', pId)
                       }).catch(function (error) {
                       console.log(error)
                   })
               }
           }
    }
});

export default store;