<template>
  <ul class="router-language-switcher ">
    <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)" class="btn">
      {{ locale }}
    </li>
    <li>
        <v-icon
          color="blue darken-1">
              <i class="material-icons">language</i>
        </v-icon>
    </li>
  </ul>

</template>

<script>
// import { Trans } from '@/plugins/Translation'

export default {
  name: 'LocaleSwitcher',
  data: () => ({
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  }),
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale){
        this.$i18n.locale = locale
        this.$router.push({name: this.$route.name, params: { locale: locale }})
      }
    }
  }
}
</script>

<style scoped>
.router-language-switcher {
  margin: 0 30px;
}
li {
  text-decoration: none;
  color:#666; text-transform: uppercase;
  font-size: 12px;
  padding:0 5px;
  display:inline-block;
}
li.btn{ cursor: pointer;}
li:first-child{ border-right: 1px solid #666; }
</style>
