<template>
  <v-row
      justify="center"
      no-gutters
      class ="footNav"
  >
        <router-link :to="{ name: 'Imprint', params: { locale: locale }}">
         {{ $t('imprint.name') }}
        </router-link>
        <router-link :to="{ name: 'Contact', params: { locale: locale }}">
         {{ $t('contact.name') }}
        </router-link>
        <router-link :to="{ name: 'Dataprotection', params: { locale: locale }}">
         {{ $t('dataprotection.name') }}
        </router-link>
      <v-col
          class="py-4 text-center"
          cols="12">
        {{ new Date().getFullYear() }} &middot; <a href="https://netschach.de" target="_blank" class="extern"><strong>Netschach</strong></a>
      </v-col>
  </v-row>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: 'App',
  setup() {
    const{ t, locale} = useI18n();
    return{t, locale}
  },
}
</script>

<style scoped>

footer a.extern{ font-size: 16px ; text-transform: uppercase}
.footNav a, a.router-link-active{
  color:#666; text-transform: uppercase;
  font-size: 12px;
  margin:0 5px;
}
</style>
