<template>
  <v-list nav>
    <router-link :to="{ name: 'Home', params: { locale: this.$i18n.locale }}">
      <v-btn
          class="bg-purple-darken-2 mx-1 my-1"
      >{{ $t('home.name') }} </v-btn>
    </router-link>
    <router-link :to="{ name: 'About', params: { locale: this.$i18n.locale }}">
      <v-btn
          class="bg-purple-accent-4 mx-1 my-1"
      >{{ $t('about.name') }} </v-btn>
    </router-link>
    <router-link :to="{ name: 'Spiel', params: { locale: this.$i18n.locale }}">
      <v-btn
          class="bg-blue-lighten-2 text-white mx-1 my-1"
      >{{ $t('game.name') }} </v-btn>
    </router-link>
  </v-list>
</template>