<link rel="stylesheet" href="styles/variables.scss">
<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <v-app-bar-title>
        <img
          :src="require(`@/assets/chessfruits.png`)"
          alt="Chessfruit.com - Dedicated to chess beginners"
          class="chessfruit"
        >
      </v-app-bar-title>

      <v-spacer />

      <!-- desktop & tablet navigation -->
      <nav class="d-none d-sm-flex">
        <navigation />
        <LocaleSwitcher />
      </nav>
          <v-btn
              icon
              @click="drawer = !drawer"
              class="d-flex d-sm-none"
          >
                <i class="material-icons">dehaze</i>
          </v-btn>

    </v-app-bar>

    <!-- mobile navigation -->
    <v-navigation-drawer
      v-model="drawer"
      class="d-flex d-sm-none"
      @click="drawer = !drawer"
    >
      <navigation />

      <LocaleSwitcher />

    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer class="bg-transparent">
      <footera />
    </v-footer>

  </v-app>
</template>
<script>
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import navigation from "./components/navigation";
import LocaleSwitcher from "./components/LocaleSwitcher";
import footera from "./components/footera";

export default {
  name: 'App',
  components: {
    navigation,
    LocaleSwitcher,
    footera
  },
  setup(){
    const store = useStore();
    const{ t, locale} = useI18n();
    return{store, t, locale}
  },
  data: () => ({
    drawer: false,
  }),
  watch: {
    locale(){
      this.store.state.lang = this.$i18n.locale;
      this.store.commit('setNota');
    }
  },
  mounted(){ // Sprach Check // First Visit!
    if(window.location.pathname.indexOf("/en") !== -1) { this.$i18n.locale = 'en' } // browser zugriff (reload or link) z.b auf: /en/game
    else{
      if(window.location.pathname =='/'){
        // beim ersten Besuch wird die Browser language zur Sprachwahl genutzt
        // console.log('first visit: '+ window.location.pathname)
        const browserLang = window.navigator.language
        if (browserLang.split('-')[0] != 'de'){ this.$i18n.locale = 'en' }
        // default is: this.$i18n.locale = 'de'
      }
    }
  }
}
</script>
<style lang="scss">
.chessfruit{ max-height: 40px;}
h1 {
  font-family: Georgia;
  text-shadow: 1px 1px 1px #ccc;
  margin:20px 0;
  font-size: 30px;
}
h2{
   font-family: Georgia;
   text-shadow: 1px 1px 1px #ccc;
 }

h3 {
  font-family: Georgia;
  text-shadow: 1px 1px 1px #ccc;
}

blockquote{ font-family: Georgia; }
figcaption{ font-style: italic; font-size: 12px; margin-top: 12px; }
.v-toolbar-title__placeholder{ overflow: visible !important; }
.v-application a {text-decoration: none;}
.v-btn__overlay{ transition: none; }/* chrome blur bug */

a.arrow{
  background-size: 18px 32px;
  padding: 5px 0 6px 26px;
  line-height: 28px;
  font-size: 20px;
  color: #996633;
  background: no-repeat url("assets/arrow.svg");
}

.bottom-spacer{ margin:10px 0 20px 0; }
.px16{ font-size: 16px; }
.px16-xs12{ font-size: 16px; }
.px16-xs10{ font-size: 16px; }
.px12{ font-size: 12px; }
.router-language-switcher{ margin: 0 30px;}
.nowrap{ white-space: nowrap }

@media only screen and (max-width: 800px) {
  header .v-btn{  font-size: 14px;}
  .router-language-switcher{ margin: 0 5px; white-space: nowrap;}
}

@media only screen and (max-width: 600px) {
  .v-navigation-drawer__content .v-btn{width:100%;}
  .router-language-switcher{ margin: 0 auto !important; width: 80px; }
  .v-app-bar-title{padding:8px 10px 4px 10px;}
  h1{ font-size: 22px; margin:15px 0 15px ; }
  h3{ font-size: 18px; margin:0; }
  .px16-xs12{ font-size: 12px; }
  .px16-xs10{ font-size: 10px; }
  .bottom-spacer{ margin-bottom: 0px; }
  .chessfruit{ width:100%; }
  .chessfruitNota { width: 180px !important;}
}

/*
xs	Small to large phone	< 600px
sm	Small to medium tablet	600px > < 960px
md	Large tablet to laptop	960px > < 1264px*
lg	Desktop	1264px > < 1904px*
xl	4k and ultra-wide	> 1904px*
*/

/* Border */

#a8::before,#a7::before,#a6::before,#a5::before,#a4::before,#a3::before,#a2::before,#a1::before{border-left:1px solid #666;}
#a1,#b1,#c1,#d1,#e1,#f1,#g1,#h1{border-bottom:1px solid #666;}

/* gray sQuare rotation
.nosw, .wnos, .swno, .oswn {
  div.white::before, div.gray::before {
    border-right:1px solid #666;
    border-top:1px solid #666;
  }
}
.nosw{
  #a1,#a2,#a3,#a4,#a5,#a6,#a7,#a8{
    .rotCon { border-left:1px solid #666; }
  }
  #a1,#b1,#c1,#d1,#e1,#f1,#g1,#h1{
    .rotCon{ border-bottom:1px solid #666;}
  }
}
.swno{
  #h1,#h2,#h3,#h4,#h5,#h6,#h7,#h8{
    .rotCon { border-left:1px solid #666;}
  }
  #a8,#b8,#c8,#d8,#e8,#f8,#g8,#h8{
    .rotCon { border-bottom:1px solid #666; }
  }
}
.wnos{
  #a1,#b1,#c1,#d1,#e1,#f1,#g1,#h1{
    .rotCon {border-left:1px solid #666;}
  }
  #h1,#h2,#h3,#h4,#h5,#h6,#h7,#h8{
    .rotCon { border-bottom:1px solid #666; }
  }
}
.oswn{
  #a8,#b8,#c8,#d8,#e8,#f8,#g8,#h8{
    .rotCon { border-left:1px solid #666; }
  }
  #a1,#a2,#a3,#a4,#a5,#a6,#a7,#a8{
    .rotCon {  border-bottom:1px solid #666;}
  }
}

*/

</style>