import { createWebHistory, createRouter } from "vue-router";
import i18n from '../i18n'
import Home from '../views/Home.vue'

const routes = [{

    path: '/:locale',
    props: true,
    component: {
        template: "<router-view></router-view>"
    },
    beforeEnter: (to, from, next) => {
        const locale = to.params.locale; // console.log('locale: ' + locale);
        // page ist gerendert - dann kommt erst der router
        // ergo: to lame -> in app.vue onmounted url check!!

        if(locale == 'de') document.querySelector('html').setAttribute('lang', locale)

        const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        if(!supported_locales.includes(locale)) return next('de');
        if(i18n.locale !== locale) { i18n.locale = locale; }
        return next()
    },
    children: [
          {
            path: '',
            name: 'Home',
            component: Home,
              meta: {
                  title:
                      {
                          de: 'Notationstrainer für Schachanfänger - Chessfruits.com',
                          en: 'Notations trainer for Chess Beginners - Chessfruits.com'
                      }
                  ,
                  metaTags: {
                      de:[
                          {
                              name: 'description',
                              content: 'Chessfruits möchte Schachanfängern das Erlernen der Sprache des Schachs, die Verinnerlichung von Schachnotationen auf spielerische Art und Weise ermöglichen'
                          },
                          {
                              property: 'og:description',
                              content: 'Chessfruits möchte Schachanfängern das Erlernen der Sprache des Schachs, die Verinnerlichung von Schachnotationen auf spielerische Art und Weise ermöglichen'
                          }
                      ],
                      en:[
                          {
                              name: 'description',
                              content: 'Chessfruits wants to enable chess beginners to learn the language of chess, to internalize chess notations in a playful way'
                          },
                          {
                              property: 'og:description',
                              content: 'Chessfruits wants to enable chess beginners to learn the language of chess, to internalize chess notations in a playful way'
                          }
                      ]
                  }
              }
          },
          {
            path: 'about',
            name: 'About',
            component: () => import('../views/About.vue'),
            meta: {
                title:{
                    de: 'Notation·Mill - Entwickelt zur schnellen Erfassung von Schachnotationen',
                    en: 'Notation·Mill - Invented to learn chess notations fast!'
                },
                metaTags: {
                    de:[
                        {
                            name: 'description',
                            content: 'Chessfruits möchte das Erlernen der Schachnotationen erleichtern, dafür wurde das Notation Mill Spiel entwickelt - das Spiel für Schachbeginner'
                        },
                        {
                            property: 'og:description',
                            content: 'Chessfruits möchte das Erlernen der Schachnotationen erleichtern, dafür wurde das Notation Mill Spiel entwickelt - das Spiel für Schachbeginner'
                        }
                    ],
                        en:[
                        {
                            name: 'description',
                            content: 'Chessfruits wants to facilitate the learning of chess notations, therefore we invented the Notation Mill Game - a game for chess beginner'
                        },
                        {
                            property: 'og:description',
                            content: 'Chessfruits wants to facilitate the learning of chess notations, therefore we invented the Notation Mill Game - a game for chess beginner'
                        }
                    ]
                }
            }
          },
          {
            path: 'game',
            name: 'Spiel',
            component: () => import('../views/Spiel.vue'),
              meta: {
                  title: {
                      de: 'Notation·Mill - Das Spiel für Schachanfänger',
                      en: 'Notation·Mill - The chess beginners game'
                  },
                  metaTags: {
                      de:
                          [{ name: 'description',  content: 'Notation·Mill, das Spiel für Schachanfänger! Bist du schnell genug, um den Zug auszuführen?'},
                              { property: 'og:description', content: 'Notation·Mill, das Spiel für Schachanfänger! Bist du schnell genug, um den Zug auszuführen?'}],
                      en:
                          [{ name: 'description',  content: 'The Notation Mill Game - are you fast enough to move the pieces?'},
                              { property: 'og:description', content: 'The Notation Mill Game - are you fast enough to move the pieces?'}]
                  }
              }
          },
          {
            path: 'imprint',
            name: 'Imprint',
            component: () => import('../views/Imprint.vue'),
              meta: {
                  title:{
                      de: 'Chessfruits.com / Impressum',
                      en: 'Chessfruits.com / Legal Notice'
                  },
                  metaTags: {
                      de: [{ name: 'description', content: 'Das Impressum von Chessfruits.com'},{ property: 'og:description', content: 'Das Impressum von Chessfruits.com' }],
                      en: [{ name: 'description', content: 'Legal Notice'},{ property: 'og:description', content: 'Legal Notice' }]
                  }
              }
          },
          {
            path: 'contact',
            name: 'Contact',
            component: () => import('../views/Contact.vue'),
              meta: {
                  title: {
                      de: 'Chessfruits.com / Kontakt',
                      en: 'Chessfruits.com / Contact',
                  },
                  metaTags: {
                      de: [{name: 'description', content: 'Chessfruits.com kontaktaufnahme'},{property: 'og:description', content: 'Chessfruits.com kontaktaufnahme'}],
                      en: [{name: 'description', content: 'Chessfruits Contact Page'},{property: 'og:description', content: 'Chessfruits Contact Page'}]
                  }
              }
          },
          {
            path: 'dataprotection',
            name: 'Dataprotection',
            component: () => import('../views/Dataprotection.vue'),
              meta: {
                  title: {
                      de: 'Chessfruits.com / Datenschutzerklärung',
                      en: 'Chessfruits.com / Dataprotection'
                  },
                  metaTags: {
                      de: [{ name: 'description', content: 'Chessfruits Datenschutzerklärung'},{ property: 'og:description', content: 'Chessfruits Datenschutzerklärung' }],
                      en: [{ name: 'description', content: 'Chessfruits Dataprotection Page'},{ property: 'og:description', content: 'Chessfruits Dataprotection Page' }]
                  }
              }
          }
        ],
    },{
        path: "/:catchAll(.*)",
        redirect(){
            // FIRST VISIT => Language is not defined yet!
            const browserLang = window.navigator.language
            if(browserLang.split('-')[0] != 'de'){ // console.log('URL EMPTY!')
                return 'en'; // means redirect to /en
            }
            return process.env.VUE_APP_I18N_LOCALE; // means redirect to /de
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})



///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title); //  meta.title exist?
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags); // meta tags.
    const pageLang = to.params.locale; //console.log('locale: ' + pageLang);

      if(nearestWithTitle){// existiert? -> ok:
        let thisTitle = "";
        thisTitle = nearestWithTitle.meta.title[pageLang];
        document.title = thisTitle;
      }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  let thisMap = nearestWithMeta.meta.metaTags.de;
  if(pageLang != "de"){ thisMap = nearestWithMeta.meta.metaTags.en;}

  thisMap.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));   // Add the meta tags to the document head.

  next();
});


export default router