import { createI18n } from 'vue-i18n'
import en from '/src/locales/en.json'
import de from '/src/locales/de.json'

function loadLocaleMessages() {
  const locales = [{ en: en }, { de: de }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

const i18n = createI18n({
  locale: 'de',
  legacy: false,
  globalInjection: true,
  messages: loadLocaleMessages()
})

export default i18n




