<template>
  <div>
    <start/>
  </div>
</template>

<script>
import start from '../components/start.vue';

export default {
  name: 'Home',
  
  components: {
    start,
  },
};
</script>
