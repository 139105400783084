<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="homeCon">
            <v-img
              :src="chessfruit"
              class="my-3"
              contain
              height="500"
            />
          <div id="chessfruit120">
            <img :src="require(`@/assets/chessfruits120.png`)" alt="Chessfruit.com" />
            <h2 class="display-2 mb-3 glow" v-html="t('home.claim')"></h2>
          </div>

        </div>
      </v-col>
      <v-col class="mb-4 intro">
       <h1>Notation&middot;Mill&middot;Game</h1>
          <p v-html="t('home.intro')"></p>
          <p v-html="t('home.intro2')"></p>

        <router-link :to="{ name: 'Spiel', params: { locale: this.$i18n.locale }}" class="arrow">
          {{ t('home.game_btn') }}
        </router-link>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import chessfruit from '../assets/chessfruit.svg'
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: 'start',
  setup() {
    const store = useStore();
    let lang = computed(() => store.state.lang);
    const{ t, locale} = useI18n();

    return{
      store, lang, t, locale
    }
  },
  data: () => ({
    chessfruit
  }),
}
</script>

<style>
.homeCon{ position:relative; }

#chessfruit120{
  position:absolute;
  top:200px; width:100%;
  text-align: center; opacity:0.8;
}

#chessfruit120 img{ max-width:500px; width:100%; }

.intro p{ margin: 20px auto 30px auto; width: 50%; }
.intro h1{ margin: 0;  }

blockquote::before {
  content: '„'; left: -0.33em; top: -0.7em;
}
blockquote::after {
  content: '“'; top: -0.3em; right: -0.3em;
}

.glow {
  font-size: 33px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow:
        1px 1px 1px #000,
        0 0 4px #fff,
        0 0 8px #fff,
        0 0 12px #e60073,
        0 0 16px #e60073;
  }
  to {
    text-shadow:
        1px 1px 1px #000,
        0 0 8px #fff,
        0 0 16px #f95434,
        0 0 24px #f95434,
        0 0 32px #f95434;
  }
}

@media only screen and (max-width: 600px) {
  .intro p{
    width: 90%;
  }
}

</style>
